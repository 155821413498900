import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import logo from '../assets/react.svg';


const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Index() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <section className='relative overflow-hidden' style={{ backgroundImage: "url(/gradia-assets/elements/hero/line.svg), linear-gradient(98.24deg, #000000 0%, #0f8be2 100%)" }}>
          {/* <section>
            <div className='flex items-center justify-between px-8 py-5'>
              <div className='w-auto'>
                <div className='flex flex-wrap items-center'>
                  <div className='w-auto mr-14'>
                    <a href='#' className='text-white'>
                      Frontend Developer
                      <img
                        src='gradia-assets/logos/gradia-name-white.svg'
                        alt=''
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div className='w-auto hidden lg:block'>
                <ul className='flex items-center mr-10'>
                  <li className='font-heading mr-9 text-white hover:text-gray-200 text-lg'>
                    <a href='#'>Features</a>
                  </li>
                  <li className='font-heading mr-9 text-white hover:text-gray-200 text-lg'>
                    <a href='#'>Solutions</a>
                  </li>
                  <li className='font-heading mr-9 text-white hover:text-gray-200 text-lg'>
                    <a href='#'>Resources</a>
                  </li>
                  <li className='font-heading text-white hover:text-gray-200 text-lg'>
                    <a href='#'>Pricing</a>
                  </li>
                </ul>
              </div>
              <div className='w-auto'>
                <div className='flex flex-wrap items-center'>
                  <div className='w-auto hidden lg:block'>
                    <a href="mailto:martin.novak@martinn3.eu" className='font-heading block py-3.5 px-5 uppercase text-xs tracking-px text-white font-bold bg-white bg-opacity-20 hover:bg-opacity-10 transition ease-in rounded-10 block'>
                      martin.novak@martinn3.eu
                    </a>
                  </div>
                  <div className='w-auto lg:hidden'>
                    <a href='#'>
                      <svg
                        className='navbar-burger text-gray-800'
                        width={51}
                        height={51}
                        viewBox='0 0 56 56'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <rect
                          width={56}
                          height={56}
                          rx={28}
                          fill='currentColor'
                        />
                        <path
                          d='M37 32H19M37 24H19'
                          stroke='white'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className='hidden navbar-menu fixed top-0 left-0 bottom-0 w-4/6 sm:max-w-xs z-50'>
              <div className='navbar-backdrop fixed inset-0 bg-gray-800 opacity-80' />
              <nav className='relative z-10 px-9 py-8 bg-white h-full'>
                <div className='flex flex-wrap justify-between h-full'>
                  <div className='w-full'>
                    <div className='flex items-center justify-between -m-2'>
                      <div className='w-auto p-2'>
                        <a className='inline-block' href='#'>
                          <img
                            src='gradia-assets/logos/gradia-name-black.svg'
                            alt=''
                          />
                        </a>
                      </div>
                      <div className='w-auto p-2'>
                        <a className='navbar-burger' href='#'>
                          <svg
                            width={24}
                            height={24}
                            viewBox='0 0 24 24'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M6 18L18 6M6 6L18 18'
                              stroke='#111827'
                              strokeWidth={2}
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </svg>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className='flex flex-col justify-center py-8 w-full'>
                    <ul>
                      <li className='mb-12'>
                        <a
                          className='font-heading font-medium text-lg text-gray-900 hover:text-gray-700'
                          href='#'
                        >
                          Features
                        </a>
                      </li>
                      <li className='mb-12'>
                        <a
                          className='font-heading font-medium text-lg text-gray-900 hover:text-gray-700'
                          href='#'
                        >
                          Solutions
                        </a>
                      </li>
                      <li className='mb-12'>
                        <a
                          className='font-heading font-medium text-lg text-gray-900 hover:text-gray-700'
                          href='#'
                        >
                          Resources
                        </a>
                      </li>
                      <li>
                        <a
                          className='font-heading font-medium text-lg text-gray-900 hover:text-gray-700'
                          href='#'
                        >
                          Pricing
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className='flex flex-col justify-end w-full'>
                    <div className='flex flex-wrap'>
                      <div className='w-full'>
                        <button className='p-0.5 font-heading block w-full text-lg text-gray-900 font-medium rounded-10'>
                          <div className='py-2 px-5 rounded-10'>
                            <p>Login</p>
                          </div>
                        </button>
                      </div>
                      <div className='w-full'>
                        <button className='group relative p-0.5 font-heading block w-full text-lg text-gray-900 font-medium bg-gradient-cyan overflow-hidden rounded-10'>
                          <div className='absolute top-0 left-0 transform -translate-y-full group-hover:-translate-y-0 h-full w-full bg-gradient-cyan transition ease-in-out duration-500' />
                          <div className='py-2 px-5 bg-white rounded-lg'>
                            <p className='relative z-10'>Start Free Trial</p>
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </nav>
            </div>
          </section> */}
          <div className='relative z-10 container mx-auto'>
            <div className='flex flex-wrap justify-center items-center pt-32'>
              <div className='basis-1/3 px-4'>
                <img src={logo} className="App-logo" alt="logo" />
              </div>
              <div className='basis-full'>
                <h1 className='mb-4 font-heading text-center text-white text-8xl md:text-10xl font-bold  px-4'>
                  I'm React frontend developer
                </h1>
                <p class="mb-9 font-medium text-2xl text-center text-white px-4">with expertize in backend and serverless</p>
                <p className='mb-12 font-medium text-4xl text-center text-white px-4'>
                  Web applications ⋅ Websites ⋅ Mobile applications
                </p>
                {/* <div className='flex flex-wrap justify-center items-center mb-7 -m-1.5'>
                  <div className='w-full lg:w-auto p-1.5'>
                    <button className='group relative font-heading px-6 py-5 w-full lg:w-auto uppercase text-white text-xs font-semibold tracking-px bg-gray-900 hover:bg-gray-800 overflow-hidden rounded-md'>
                      <div className='absolute top-0 left-0 transform -translate-x-full group-hover:-translate-x-0 h-full w-full transition ease-in-out duration-500 bg-gradient-indigo' />
                      <p className='relative z-10'></p>
                    </button>
                  </div>
                </div> */}
                {/* <p className='mb-7 text-white text-sm text-center'>
                  Start with 14 days free trial. No credit card required
                </p> */}
                {/* <div className='mb-24 flex items-center mx-auto p-2.5 bg-white bg-opacity-20 max-w-max rounded-full'>
                  <svg
                    className='mr-2.5'
                    width={26}
                    height={26}
                    viewBox='0 0 26 26'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M17.8204 18.5236C18.7768 20.1833 19.7226 21.8241 20.6676 23.4632C16.4829 26.6669 9.97223 27.0541 5.15254 23.3647C-0.393884 19.1156 -1.30549 11.8655 1.66398 6.64649C5.07929 0.643554 11.4705 -0.684032 15.5855 0.289436C15.4742 0.531203 13.0096 5.64422 13.0096 5.64422C13.0096 5.64422 12.8148 5.65702 12.7046 5.65915C11.4883 5.71071 10.5823 5.99371 9.61136 6.49574C8.54606 7.05162 7.63313 7.85997 6.95236 8.85013C6.27159 9.8403 5.84371 10.9821 5.70611 12.1758C5.56254 13.3864 5.72994 14.6136 6.19249 15.7415C6.58358 16.6951 7.1368 17.542 7.87846 18.257C9.01619 19.3549 10.3701 20.0347 11.9394 20.2597C13.4256 20.4731 14.8549 20.2619 16.1942 19.5888C16.6966 19.3368 17.1239 19.0584 17.6234 18.6765C17.6871 18.6353 17.7436 18.583 17.8204 18.5236Z'
                      fill='white'
                    />
                    <path
                      d='M17.8296 3.96824C17.5868 3.72932 17.3618 3.50888 17.1378 3.28703C17.0041 3.15477 16.8754 3.01717 16.7385 2.88811C16.6894 2.84154 16.6318 2.7779 16.6318 2.7779C16.6318 2.7779 16.6784 2.67906 16.6983 2.63852C16.9604 2.11268 17.371 1.72834 17.8581 1.42258C18.3967 1.0819 19.0243 0.908555 19.6614 0.924467C20.4766 0.940466 21.2347 1.14348 21.8743 1.6903C22.3464 2.09384 22.5885 2.60581 22.6312 3.21699C22.7023 4.24805 22.2757 5.03771 21.4284 5.58879C20.9307 5.91304 20.3938 6.1637 19.8555 6.46058C19.5586 6.62448 19.3048 6.76847 19.0147 7.06499C18.7594 7.36258 18.7469 7.6431 18.7469 7.6431L22.6035 7.63812V9.35574H16.6507C16.6507 9.35574 16.6507 9.23841 16.6507 9.1897C16.6279 8.34565 16.7264 7.55137 17.1129 6.78483C17.4684 6.08157 18.0209 5.56675 18.6847 5.17032C19.196 4.86491 19.7343 4.60501 20.2466 4.30103C20.5627 4.11366 20.786 3.83883 20.7842 3.44027C20.7842 3.09824 20.5353 2.79425 20.1798 2.69932C19.3414 2.4732 18.4881 2.83407 18.0444 3.60133C17.9797 3.71332 17.9136 3.8246 17.8296 3.96824Z'
                      fill='white'
                    />
                    <path
                      d='M25.2892 16.698L22.0392 11.0854H15.6079L12.3369 16.756H18.8156L22.0126 22.3418L25.2892 16.698Z'
                      fill='white'
                    />
                  </svg>
                  <div className='flex items-center'>
                    <svg
                      className='mr-1'
                      width={18}
                      height={18}
                      viewBox='0 0 18 18'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M7.85874 1.71248C8.21796 0.606907 9.78205 0.606908 10.1413 1.71248L11.4248 5.66264C11.5854 6.15706 12.0462 6.49182 12.566 6.49182H16.7195C17.8819 6.49182 18.3653 7.97935 17.4248 8.66264L14.0646 11.104C13.644 11.4095 13.468 11.9512 13.6287 12.4456L14.9122 16.3958C15.2714 17.5013 14.006 18.4207 13.0656 17.7374L9.70535 15.2961C9.28476 14.9905 8.71525 14.9905 8.29466 15.2961L4.93446 17.7374C3.994 18.4207 2.72863 17.5013 3.08785 16.3958L4.37133 12.4456C4.53198 11.9512 4.35599 11.4095 3.93541 11.104L0.575205 8.66264C-0.365251 7.97935 0.118079 6.49182 1.28055 6.49182H5.43399C5.95386 6.49182 6.41461 6.15706 6.57525 5.66264L7.85874 1.71248Z'
                        fill='#FDE047'
                      />
                    </svg>
                    <svg
                      className='mr-1'
                      width={18}
                      height={18}
                      viewBox='0 0 18 18'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M7.85874 1.71248C8.21796 0.606907 9.78205 0.606908 10.1413 1.71248L11.4248 5.66264C11.5854 6.15706 12.0462 6.49182 12.566 6.49182H16.7195C17.8819 6.49182 18.3653 7.97935 17.4248 8.66264L14.0646 11.104C13.644 11.4095 13.468 11.9512 13.6287 12.4456L14.9122 16.3958C15.2714 17.5013 14.006 18.4207 13.0656 17.7374L9.70535 15.2961C9.28476 14.9905 8.71525 14.9905 8.29466 15.2961L4.93446 17.7374C3.994 18.4207 2.72863 17.5013 3.08785 16.3958L4.37133 12.4456C4.53198 11.9512 4.35599 11.4095 3.93541 11.104L0.575205 8.66264C-0.365251 7.97935 0.118079 6.49182 1.28055 6.49182H5.43399C5.95386 6.49182 6.41461 6.15706 6.57525 5.66264L7.85874 1.71248Z'
                        fill='#FDE047'
                      />
                    </svg>
                    <svg
                      className='mr-1'
                      width={18}
                      height={18}
                      viewBox='0 0 18 18'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M7.85874 1.71248C8.21796 0.606907 9.78205 0.606908 10.1413 1.71248L11.4248 5.66264C11.5854 6.15706 12.0462 6.49182 12.566 6.49182H16.7195C17.8819 6.49182 18.3653 7.97935 17.4248 8.66264L14.0646 11.104C13.644 11.4095 13.468 11.9512 13.6287 12.4456L14.9122 16.3958C15.2714 17.5013 14.006 18.4207 13.0656 17.7374L9.70535 15.2961C9.28476 14.9905 8.71525 14.9905 8.29466 15.2961L4.93446 17.7374C3.994 18.4207 2.72863 17.5013 3.08785 16.3958L4.37133 12.4456C4.53198 11.9512 4.35599 11.4095 3.93541 11.104L0.575205 8.66264C-0.365251 7.97935 0.118079 6.49182 1.28055 6.49182H5.43399C5.95386 6.49182 6.41461 6.15706 6.57525 5.66264L7.85874 1.71248Z'
                        fill='#FDE047'
                      />
                    </svg>
                    <svg
                      className='mr-1'
                      width={18}
                      height={18}
                      viewBox='0 0 18 18'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M7.85874 1.71248C8.21796 0.606907 9.78205 0.606908 10.1413 1.71248L11.4248 5.66264C11.5854 6.15706 12.0462 6.49182 12.566 6.49182H16.7195C17.8819 6.49182 18.3653 7.97935 17.4248 8.66264L14.0646 11.104C13.644 11.4095 13.468 11.9512 13.6287 12.4456L14.9122 16.3958C15.2714 17.5013 14.006 18.4207 13.0656 17.7374L9.70535 15.2961C9.28476 14.9905 8.71525 14.9905 8.29466 15.2961L4.93446 17.7374C3.994 18.4207 2.72863 17.5013 3.08785 16.3958L4.37133 12.4456C4.53198 11.9512 4.35599 11.4095 3.93541 11.104L0.575205 8.66264C-0.365251 7.97935 0.118079 6.49182 1.28055 6.49182H5.43399C5.95386 6.49182 6.41461 6.15706 6.57525 5.66264L7.85874 1.71248Z'
                        fill='#FDE047'
                      />
                    </svg>
                    <svg
                      className='mr-1'
                      width={18}
                      height={18}
                      viewBox='0 0 18 18'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M7.85874 1.71248C8.21796 0.606907 9.78205 0.606908 10.1413 1.71248L11.4248 5.66264C11.5854 6.15706 12.0462 6.49182 12.566 6.49182H16.7195C17.8819 6.49182 18.3653 7.97935 17.4248 8.66264L14.0646 11.104C13.644 11.4095 13.468 11.9512 13.6287 12.4456L14.9122 16.3958C15.2714 17.5013 14.006 18.4207 13.0656 17.7374L9.70535 15.2961C9.28476 14.9905 8.71525 14.9905 8.29466 15.2961L4.93446 17.7374C3.994 18.4207 2.72863 17.5013 3.08785 16.3958L4.37133 12.4456C4.53198 11.9512 4.35599 11.4095 3.93541 11.104L0.575205 8.66264C-0.365251 7.97935 0.118079 6.49182 1.28055 6.49182H5.43399C5.95386 6.49182 6.41461 6.15706 6.57525 5.66264L7.85874 1.71248Z'
                        fill='#FDE047'
                      />
                    </svg>
                  </div>
                </div> */}

              </div>
            </div>
          </div>
        </section>
        <section className='relative bg-black overflow-hidden pt-24'>
          <div className='container mx-auto px-4'>
            <h2 className='mb-4 font-heading font-semibold text-center text-6xl sm:text-7xl text-white'>
              Connect with me
            </h2>
            <p className='mb-11 text-gray-400 text-center'>
              {' '}
              Reach Out for Collaboration, Ideas, and More
            </p>
            <div className='flex flex-wrap items-end -m-6'>

              <div className='w-full lg:w-1/2 p-6 lg:flex lg:justify-end'>
                <img
                  src='images/martinnovak.png'
                  alt=''
                />
              </div>
              <div className='w-full lg:w-1/2 p-6'>
                <div className='flex flex-wrap'>
                  <div className='w-full'>
                    <div className='lg:max-w-sm p-0.5 rounded-10'>
                      <div className='p-7 rounded-10'>
                        <div className='flex flex-wrap -m-2 mb-4'>
                          <div className='w-auto p-2'>
                            <img
                              src='gradia-assets/elements/features/folder-open.svg'
                              alt=''
                            />
                          </div>
                          <div className='w-auto p-2'>
                            <h3 className='font-heading font-bold text-white text-xl'>
                              Versatile Web Development
                            </h3>
                          </div>
                        </div>
                        <p className='text-gray-400'>
                          With extensive expertise, I proficiently develop web
                          applications across diverse frameworks, ensuring
                          robust and versatile solutions.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='w-full'>
                    <div className='lg:max-w-sm p-0.5 bg-gradient-green rounded-10'>
                      <div className='p-7 bg-gradient-black rounded-10'>
                        <div className='flex flex-wrap -m-2 mb-4'>
                          <div className='w-auto p-2'>
                            <img
                              src='gradia-assets/elements/features/chart-square-bar.svg'
                              alt=''
                            />
                          </div>
                          <div className='w-auto p-2'>
                            <h3 className='font-heading font-bold text-white text-xl'>
                              Mentoring for Junior Developers
                            </h3>
                          </div>
                        </div>
                        <p className='text-gray-400'>
                          I excel in mentoring junior developers, guiding them
                          with expertise and fostering their professional
                          growth.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='w-full'>
                    <div className='lg:max-w-sm p-0.5 rounded-10'>
                      <div className='p-7 rounded-10'>
                        <div className='flex flex-wrap -m-2 mb-4'>
                          <div className='w-auto p-2'>
                            <img
                              src='gradia-assets/elements/features/chat-alt.svg'
                              alt=''
                            />
                          </div>
                          <div className='w-auto p-2'>
                            <h3 className='font-heading font-bold text-white text-xl'>
                              Listening, Responding, Resolving
                            </h3>
                          </div>
                        </div>
                        <p className='text-gray-400'>
                          I am approachable, attentive, and responsive, creating
                          an environment where open communication thrives and
                          issues are promptly addressed.
                        </p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='pt-24 pb-32 overflow-hidden'>
          <div className='container mx-auto px-4'>
            <h2 className='mb-20 font-heading font-bold text-6xl sm:text-8xl xl:text-10xl text-gray-900'>
              Career
            </h2>
            <p className='mb-7 font-heading font-medium text-xl sm:text-2xl text-gray-900'>
              Development
            </p>
            <div className='flex flex-wrap -m-3'>
              <div className='w-full md:w-1/2 xl:w-1/3 p-3'>
                <a
                  className='block h-full p-6 border border-gray-200 hover:border-gray-300 rounded-md'
                  href='https://www.staffers.com/'
                >
                  <p className='mb-7 font-heading font-semibold text-xs text-gray-500 uppercase tracking-px'>
                    STAFFERS, 2024
                  </p>
                  <h3 className='mb-1.5 font-heading font-semibold text-lg text-gray-900'>
                    Technical Lead
                  </h3>
                  <p className='text-gray-600 text-sm'>Full Time</p>
                </a>
              </div>
              <div className='w-full md:w-1/2 xl:w-1/3 p-3'>
                <a
                  className='block h-full p-6 border border-gray-200 hover:border-gray-300 rounded-md'
                  href='https://codasip.com/'
                >
                  <p className='mb-7 font-heading font-semibold text-xs text-gray-500 uppercase tracking-px'>
                    CODASIP, 2022
                  </p>
                  <h3 className='mb-1.5 font-heading font-semibold text-lg text-gray-900'>
                    Frontend Developer
                  </h3>
                  <p className='text-gray-600 text-sm'>Full Time</p>
                </a>
              </div>
              <div className='w-full md:w-1/2 xl:w-1/3 p-3'>
                <a
                  className='block h-full p-6 border border-gray-200 hover:border-gray-300 rounded-md'
                  href='https://www.cleverlance.de/'
                >
                  <p className='mb-7 font-heading font-semibold text-xs text-gray-500 uppercase tracking-px'>
                    CLEVERLANCE ENTERPRISE SOLUTIONS, 2018
                  </p>
                  <h3 className='mb-1.5 font-heading font-semibold text-lg text-gray-900'>
                    Frontend Developer
                  </h3>
                  <p className='text-gray-600 text-sm'>Full Time</p>
                </a>
              </div>
              <div className='w-full md:w-1/2 xl:w-1/3 p-3'>
                <div
                  className='h-full p-6 border border-gray-200 hover:border-gray-300 rounded-md'
                >
                  <p className='mb-7 font-heading font-semibold text-xs text-gray-500 uppercase tracking-px'>
                    FREELANCER, 2016-now
                  </p>
                  <h3 className='mb-1.5 font-heading font-semibold text-lg text-gray-900'>
                    Fullstack developer
                  </h3>
                  <p className='text-gray-600 text-sm'>&nbsp;</p>
                </div>
              </div>
              <div className='w-full md:w-1/2 xl:w-1/3 p-3'>
                <div
                  className=' h-full p-6 border border-gray-200 hover:border-gray-300 rounded-md'

                >
                  <p className='mb-7 font-heading font-semibold text-xs text-gray-500 uppercase tracking-px'>
                    MULTIPLE WEBAGENCIES, 2014-2018
                  </p>
                  <h3 className='mb-1.5 font-heading font-semibold text-lg text-gray-900'>
                    HTML, CSS and Jquery Developer
                  </h3>
                  <p className='text-gray-600 text-sm'>
                    Webwings 2014, Roxar Solutions 2017, Sellastica 2018
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section className='pt-20 pb-36 bg-black overflow-hidden'>
          <div className='container mx-auto px-4'>
            <div className='md:max-w-lg mx-auto text-center mb-20'>
              <h2 className='mb-4 font-heading font-semibold text-white text-6xl sm:text-7xl'>
                Latest public projects
              </h2>
              <p className='text-lg text-gray-400'>
                Unveiling Our Newest Creations: Check Out My Latest Website
                Projects!
              </p>
            </div>
            <div className='flex flex-wrap -m-3'>
              <div className='w-full md:w-1/2 xl:w-1/4 p-3'>
                <a className='group' href='#'>
                  <div className='group flex flex-col justify-end h-full relative overflow-hidden rounded-10'>
                    <img
                      className='mx-auto w-full transform group-hover:scale-110 transition ease-out duration-500'
                      src='gradia-assets/images/blog/blog1.png'
                      alt=''
                    />
                    <div className='absolute bottom-0 left-0 w-full p-2.5'>
                      <div className='p-5 w-full bg-black bg-opacity-80 rounded-md'>
                        <h2 className='mb-2 font-heading font-medium text-xs uppercase text-gray-400 tracking-px'>
                          Technology . 4 min read
                        </h2>
                        <p className='font-heading font-semibold text-lg text-white group-hover:underline'>
                          You will never believe these bizarre truth of travel.
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className='w-full md:w-1/2 xl:w-1/4 p-3'>
                <a className='group' href='#'>
                  <div className='group flex flex-col justify-end h-full relative overflow-hidden rounded-10'>
                    <img
                      className='mx-auto w-full transform group-hover:scale-110 transition ease-out duration-500'
                      src='gradia-assets/images/blog/blog2.png'
                      alt=''
                    />
                    <div className='absolute bottom-0 left-0 w-full p-2.5'>
                      <div className='p-5 w-full bg-black bg-opacity-80 rounded-md'>
                        <h2 className='mb-2 font-heading font-medium text-xs uppercase text-gray-400 tracking-px'>
                          Technology . 4 min read
                        </h2>
                        <p className='font-heading font-semibold text-lg text-white group-hover:underline'>
                          You will never believe these bizarre truth of travel.
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className='w-full md:w-1/2 xl:w-1/4 p-3'>
                <a className='group' href='#'>
                  <div className='group flex flex-col justify-end h-full relative overflow-hidden rounded-10'>
                    <img
                      className='mx-auto w-full transform group-hover:scale-110 transition ease-out duration-500'
                      src='gradia-assets/images/blog/blog3.png'
                      alt=''
                    />
                    <div className='absolute bottom-0 left-0 w-full p-2.5'>
                      <div className='p-5 w-full bg-black bg-opacity-80 rounded-md'>
                        <h2 className='mb-2 font-heading font-medium text-xs uppercase text-gray-400 tracking-px'>
                          Technology . 4 min read
                        </h2>
                        <p className='font-heading font-semibold text-lg text-white group-hover:underline'>
                          You will never believe these bizarre truth of travel.
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className='w-full md:w-1/2 xl:w-1/4 p-3'>
                <a className='group' href='#'>
                  <div className='group flex flex-col justify-end h-full relative overflow-hidden rounded-10'>
                    <img
                      className='mx-auto w-full transform group-hover:scale-110 transition ease-out duration-500'
                      src='gradia-assets/images/blog/blog4.png'
                      alt=''
                    />
                    <div className='absolute bottom-0 left-0 w-full p-2.5'>
                      <div className='p-5 w-full bg-black bg-opacity-80 rounded-md'>
                        <h2 className='mb-2 font-heading font-medium text-xs uppercase text-gray-400 tracking-px'>
                          Technology . 4 min read
                        </h2>
                        <p className='font-heading font-semibold text-lg text-white group-hover:underline'>
                          You will never believe these bizarre truth of travel.
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </section> */}
        <section className='pt-24 pb-32 bg-gradient-gray2 overflow-hidden'>
          <div className='container mx-auto px-4'>
            <div className='max-w-md mx-auto mb-20'>
              <h2 className='mb-4 font-heading font-semibold text-center text-6xl sm:text-7xl text-gray-900'>
                Cornerstones of my Professional Approach
              </h2>
              <p className='text-lg text-gray-600 text-center'>
                Navigating Success with Commitment, Precision, and Autonomy
              </p>
            </div>
            <div className='flex flex-wrap -m-6 mb-24'>
              <div className='w-full md:w-1/2 p-6'>
                <div className='md:max-w-xl'>
                  <h2 className='mb-4 font-heading font-medium text-2xl text-gray-900'>
                    Trust in Work Environment:
                  </h2>
                  <p className='text-base text-black'>
                    I am dedicated to maintaining peak functionality, starting
                    my workday promptly in the morning, and I anticipate the
                    same level of commitment from my fellow team members.
                    Recognizing the importance of accountability, I willingly
                    take responsibility when needed. I believe in a
                    collaborative approach to problem-solving, and I am
                    confident that together, we can effectively address any
                    operational challenges that may arise. My commitment to a
                    proactive and collaborative work ethic aligns seamlessly
                    with fostering a positive and productive team environment.
                  </p>
                </div>
              </div>
              <div className='w-full md:w-1/2 p-6'>
                <div className='md:max-w-xl'>
                  <h2 className='mb-4 font-heading font-medium text-2xl text-gray-900'>
                    Clarity and Punctuality in Work Expectations:
                  </h2>
                  <p className='text-base text-black'>
                    I bring a strong commitment to delivering high-quality work
                    within specified timelines. I am accustomed to roles where
                    tasks are well-defined, and I appreciate the importance of
                    completing projects punctually to avoid common pitfalls. My
                    attention to detail and organizational skills have allowed
                    me to successfully navigate complex projects, ensuring that
                    objectives are met efficiently. I am confident that my
                    dedication to structured and timely completion of tasks
                    aligns seamlessly with your organization's goals,
                    contributing to a successful and streamlined workflow.
                  </p>
                </div>
              </div>
              <div className='w-full md:w-1/2 p-6'>
                <div className='md:max-w-xl'>
                  <h2 className='mb-4 font-heading font-medium text-2xl text-gray-900'>
                    Autonomy and Flexibility in Work Culture:
                  </h2>
                  <p className='text-base text-black'>
                    I am seeking a work environment that values autonomy and
                    trusts its team members to deliver results independently.
                    Having thrived in roles where I've been granted the freedom
                    to manage my own tasks and work from various locations, I am
                    drawn to a workplace that fosters a culture of trust and
                    allows for flexibility. I believe that this approach not
                    only enhances individual productivity but also contributes
                    to a more dynamic and innovative team.
                  </p>
                </div>
              </div>
              <div className='w-full md:w-1/2 p-6'>
                <div className='md:max-w-xl'>
                  <h2 className='mb-4 font-heading font-medium text-2xl text-gray-900'>
                    Compensation and Commitment to the Workplace:
                  </h2>
                  <p className='text-base text-black'>
                    {' '}
                    I bring a strong work ethic and unwavering commitment to any
                    role I undertake, and I believe that dedication deserves
                    fair compensation. I expect to be recognized and rewarded
                    for my full commitment and continuous availability to
                    contribute to the success of the workplace. In instances
                    where compensation is tied to performance metrics, I am open
                    to an arrangement that reflects a percentage of the revenue
                    generated, aligning my financial incentives with the success
                    and growth of the organization. This approach not only
                    motivates me to excel but also establishes a mutually
                    beneficial partnership where my contributions directly
                    contribute to the company's prosperity.
                  </p>
                </div>
              </div>
            </div>
            <div
              className='group block max-w-max mx-auto font-heading font-medium text-gray-900 hover:text-gray-800 text-base overflow-hidden'
              href='#'
            >
              <p className='mb-1'>
                These outlined principles are just a glimpse into the values I
                consistently practice in my day-to-day work.
              </p>
              <div className='w-full transform -translate-x-0 group-hover:translate-x-full h-px bg-gradient-cyan transition ease-in-out duration-500' />
            </div>
          </div>
        </section>
        <section className='pt-24 pb-32 p-6 bg-gradient-white'>
          <div className='container mx-auto px-4'>
            <div class="max-w-md mx-auto mb-20"><h2 class="mb-4 font-heading font-semibold text-center text-6xl sm:text-7xl text-gray-900">Key Principles of Collaboration</h2><p class="text-lg text-gray-600 text-center">Values and Principles that Guide My Freelance Business</p></div>
            <div className='text'>
              <p>As a freelance developer, I&#39;m committed to building my business on a foundation of integrity, transparency, and social responsibility. My approach is centered around the following core
                values:</p>
              <ol>
                <li><strong>Respect for Clients</strong>: I prioritize open communication, empathy, and understanding in all interactions with clients.</li>
                <li><strong>Transparency and Honesty</strong>: I strive to be clear about my services, pricing, and capabilities, ensuring that clients have a complete picture of what they can expect from our
                  collaboration.</li>
                <li><strong>Social Impact</strong>: As a developer, I believe in using my skills to create positive change. I&#39;m committed to taking on projects that align with my values and contribute to the
                  greater good.</li>
                <li><strong>Continuous Learning</strong>: I recognize that staying up-to-date with industry developments is crucial for delivering high-quality services. I dedicate time to ongoing education and
                  skill-building to ensure my expertise remains current.</li>
                <li><strong>Collaboration and Community</strong>: As a freelancer, I value building strong relationships with other professionals in the field. I participate in online communities, attend meetups,
                  and engage in peer-to-peer learning to support others and contribute to the collective knowledge base.</li>
              </ol>
              <p className='mb-4 font-heading font-medium text-2xl text-gray-900 pt-6'>Why This Approach Matters:</p>
              <p>As a freelance developer, my approach is not just about delivering high-quality services; it&#39;s also about creating a positive impact on the world around me. By prioritizing values and
                ethics, I can:</p>
              <ul>
                <li>Build trust with clients who share similar values</li>
                <li>Attract like-minded projects that align with my passions</li>
                <li>Stay motivated and fulfilled in my work, knowing that I&#39;m making a difference</li>
              </ul>
              <p className='mb-4 font-heading font-medium text-2xl text-gray-900 pt-6'>How This Approach Applies to My Work:</p>
              <p>When working on a project, I consider the following ethical considerations:</p>
              <ul>
                <li>Is this project aligned with my values and social responsibility goals?</li>
                <li>Am I providing clear and transparent communication to all stakeholders?</li>
                <li>Am I continuously learning and improving my skills to ensure high-quality work?</li>
              </ul>
              <p>By integrating values and ethics into my freelance business, I can build a reputation as a trustworthy, socially responsible developer who prioritizes doing what&#39;s right.</p>
            </div>
          </div>

        </section>
        <section className='py-16 bg-black overflow-hidden'>
          <div className='container mx-auto px-4'>

            <div className='flex flex-wrap items-center justify-between -m-6'>
              <div className='w-auto p-6'>
                <p className='text-sm text-gray-300'>
                  © Copyright 2024. All Rights Reserved.
                </p>
              </div>
              <div className='w-auto p-6'>
                <div className='flex flex-wrap -m-6'>
                  <div className='w-auto p-6'>
                    <a
                      className='text-gray-300 hover:text-gray-400 text-sm'
                      href='#'
                    >

                      <h3 className='font-heading font-medium text-base text-white'><a href="mailto:martin.novak@martinn3.eu">martin.novak@martinn3.eu</a>
                      </h3>
                    </a>
                  </div>
                  {/* <div className='w-auto p-6'>
                    <a
                      className='text-gray-300 hover:text-gray-400 text-sm'
                      href='#'
                    >
                      Terms &amp; Conditions
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}

